import React,{Fragment, useState, useEffect} from 'react'
import Seo from "../../components/seo/seo";
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import ImagePreview from '../../components/common/ImagePreview';
import ln from '../../assets/img/icons/social/linkedin-icon.svg'
import fb from '../../assets/img/icons/social/facebook-icon.svg'
import yt from '../../assets/img/icons/social/youtube-icon.svg'
import tw from '../../assets/img/icons/social/twitter-icon.svg'
import empty from '../../assets/img/icons/empty.png'
import lsrImg from '../../assets/img/pages/vr-games/VIRSAT-Life-Saving-Rules-Aware.jpg'
import logo from '../../assets/img/virsat-logo.svg'
import graphImg from '../../assets/img/pages/vr-games/Workplace-related-Fatalities-in-EU-&-USA-(2011-2018).jpg'
import lsrImg1 from '../../assets/img/pages/vr-games/life-saving-rules.jpg'
import vrTech from '../../assets/img/pages/vr-games/NEW Virtual Reality Technology.jpg'
import isImg from '../../assets/img/pages/vr-games/International Standard.jpg'
import vrGamesImg from '../../assets/img/pages/vr-games/virsat-virtual-games.jpg'

function VrGames() {
  const [showImgPrev,setShowImgPrev]=useState(false)
  const [activeImg,setActiveImg]=useState(empty)

  const handeleActiveImg = (img)=> {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  useEffect(() => {
    if (!showImgPrev){
      setActiveImg(empty)
    }
  }, [showImgPrev])

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={()=>setShowImgPrev(!showImgPrev)} show={showImgPrev}/>
      <Seo
        title='VR Games a new way to make work significantly safer | VIRSAT'
        description='Global warming is on top of many people’s mind as it potentially impacts the whole world population. However, an actual big impact on people are workplace-related incidents.'
      />
      <div className="main-container vr-games-container">
        <div className="row">
          <div className="column">
            <div onClick={()=>handeleActiveImg(vrGamesImg)} aria-hidden style={{cursor:'zoom-in'}}>
              <StaticImage
                src='../../assets/img/pages/vr-games/virsat-virtual-games.jpg'
                alt='VIRSAT VR Games'
                title='VIRSAT VR Games'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
              />
            </div>

            <hr/>

            <h1 className="main-subtitle large primary-color">
              <strong>VR Games: A New Way to Make Work Significantly Safer</strong>
            </h1>

            <p className="justify">
            <strong>Abstract:</strong> Virtual Reality games are an innovative way to train workers in the field. These games are low cost and fun to play. The article describes the groundbreaking VR technology to train workers in the <strong>“Life-Saving Rules”</strong>, which are proven to be effective and are an industry standard.
            </p>

            <h2 className="main-subtitle">The Importance to Improve Safety at the Workspace</h2>

            <p className="justify no-mar-top">
            Global warming is on top of many people’s mind as it <strong>potentially</strong> impacts the whole world population. However, an <strong>actual</strong> big impact on people are <strong>workplace-related incidents</strong>. For all countries in the world, it is estimated that more than 80,000 people die each year based on repeated incidents<Link to="#link-group3"><sup>[1]</sup></Link>. Only in the EU and US on average resp. 3500<Link to="#link-group3"><sup>[2]</sup></Link> and 4800<Link to="#link-group3"><sup>[3]</sup></Link> workers die each year due to mostly repeated incidents. This trend is more or less stable over the last 10 years.
            </p>

            {/* <div>
              <ul className="links-anchors" id="link-group1">
                <li id="link1">
                  <a href="https://injuryfacts.nsc.org/international/work-related-injuries-around-the-world/work-related-deaths-around-the-world/">[1] https://injuryfacts.nsc.org/international/work-related-injuries-around-the-world/work-related-deaths-around-the-world/</a> (reported fatalities by UN-ILC data based on historic reporting); Based on world fraction of inhabitants USA+EU 2020, total number of fatalities is estimated.
                </li>
                <li id="link2">
                  <a href="https://ec.europa.eu/eurostat/databrowser/bookmark/5a980fe1-9caf-47e2-9b03-8b988bbb5d94?lang=en">[2] Fatal Accidents at work by NACE Rev. 2 activity [HSW_N2_02]</a> reference period 2011-2018
                </li>
                <li id="link3"><a href="https://onepetro.org/SPEHSE/proceedings/12HSE/All-12HSE/SPE-157465-MS/158675">[3] A Big Oil Company's Approach to Significantly Reduce Fatal Incidents</a></li>
              </ul>
            </div> */}

            <h2 className="main-subtitle">Workplace-related Fatalities in EU & USA (2011-2018)</h2>

            <div onClick={()=>handeleActiveImg(graphImg)} aria-hidden>
              <StaticImage
                src='../../assets/img/pages/vr-games/Workplace-related-Fatalities-in-EU-&-USA-(2011-2018).jpg'
                alt='Workplace-related Fatalities in EU & USA (2011-2018)'
                title='Workplace-related Fatalities in EU & USA (2011-2018)'
                quality="100"
                objectFit
                style={{width:'100%', cursor:'zoom-in'}}
                imgStyle={{width:'100%', cursor:'zoom-in'}}
              />
            </div>
            {/* <img src={graphImg} alt="Workplace-related Fatalities in EU & USA (2011-2018)" onClick={()=>handeleActiveImg(graphImg)}/> */}
            <p>
            Many of these incidents happened before and will happen again unless we change our ways of working. Why is there no improvement of the number of workplace-related fatalities and why is it tolerated that more than 200 people die each day somewhere in the world due to preventable workplace-related incidents? 
            </p>

            <h2 className="main-subtitle">How to Prevent Repeated Incidents to Happen – Field Study</h2>
            <p className="no-mar-top">It was proven in a large field study (&gt;500,000 people worldwide) that many lives were saved, after teaching employees and field workers about the most hazardous work activities/conditions in their industry. This was combined with the requirements to “execute work only if these hazardous conditions are eliminated”. It enabled the workers to recognize these hazardous situations and empowered them to change work conditions or to manage the work safely.</p>

            <p>These most hazardous work situations were derived from a large database of historic fatal incidents and were translated to rules which the workers in the field could implement. These were represented and known as the “Life-Saving Rules”.</p>

            <p className="mar-bottom">
            The introduction of the Life-Saving Rules led to a significant drop in fatalities (71%) within 3 years of introduction and this trend continued to improve over a 10-year period<Link to="#link-group3"><sup>[4]</sup></Link>.
            </p>
            <div onClick={()=>handeleActiveImg(lsrImg1)} aria-hidden style={{cursor:'zoom-in'}}>
              <StaticImage
                src='../../assets/img/pages/vr-games/life-saving-rules.jpg'
                alt='Life-Saving Rules'
                title='Life-Saving Rules'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
              />
            </div>
            <p>
            There are many elements as to why this field study was so successful. One element continues to be important and that is training of workers, employees, and visitors about these Life-Saving Rules. It is not only important for those who work in the field, those who are most vulnerable for incidents, but the training is just as important for those who create the work conditions.
            </p>

            {/* <div>
              <ul className="links-anchors" id="link-group2">
                <li id="link4">
                  <a href="https://onepetro.org/SPEHSE/proceedings/12HSE/All-12HSE/SPE-157465-MS/158675">[4] A Big Oil Company's Approach to Significantly Reduce Fatal Incidents</a> 
                </li>
              </ul>
            </div> */}

            <p>
            There are many elements as to why this field study was so successful. One element continues to be important and that is training of workers, employees, and visitors about these Life-Saving Rules. It is not only important for those who work in the field, those who are most vulnerable to incidents, but the training is just as important for those who create the work conditions.
            </p>
            <p>
            There are many people in the organization who directly or indirectly create conditions for incidents, often, they don’t even realise they do. An example is the employees of a procurement department, who include the HSE contract clauses and evaluate contractors’ capabilities. If nothing is mentioned about the Life-Saving Rules in the contract, the contractor company might not have budgeted for it to comply with these rules and the worker in the field has to improvise and might choose the quickest way to execute the job. This way could be an unsafe way of working and the worker exposes himself to a life-threatening risk.
            </p>

            <h2 className="main-subtitle">International Standard</h2>
              <p className="no-mar-top mar-bottom">These Life-Saving Rules were adapted by IOGP in 2012 and IOGP 
            made it as an international standard. IOGP rejuvenated these rules in 2018<Link to="#link-group3"><sup>[5]</sup></Link> and standardized it to 9 Life-Saving Rules.</p>
            <div onClick={()=>handeleActiveImg(isImg)} aria-hidden style={{cursor:'zoom-in'}}>
              <StaticImage
                src='../../assets/img/pages/vr-games/International Standard.jpg'
                alt='International Standard'
                title='International Standard'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
              />
            </div>

            {/* <div className="international-standard">
              <ul>
                <li>
                  <h3 className="main-subtitle" style={{marginTop:0}}>Instnational Standard</h3>
                  <p className="no-mar-top">These Life-Saving Rules were adapted by IOGP in 2012 and IOGP 
                made it as an international standard. IOGP rejuvenated these rules in 2018<Link to="#link5"><sup>[5]</sup></Link> and standardized it to 9 Life-Saving Rules.</p>
                </li>
                <li>
                  <StaticImage
                    src='../../assets/img/pages/vr-games/is/Life Saving Rules Icons-01.jpg'
                    alt='Life-Saving Rules Icon 1'
                    title='Life-Saving Rules Icon 1'
                    quality="100"
                    objectFit
                    style={{width:'100%'}}
                    imgStyle={{width:'100%'}}
                  />
                </li>
              </ul>
            </div>
            <div className="international-standard1">

              <StaticImage
                src='../../assets/img/pages/vr-games/is/Life Saving Rules Icons-02.jpg'
                alt='Life-Saving Rules Icon 2'
                title='Life-Saving Rules Icon 2'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
                className="is-image"
              />
              <StaticImage
                src='../../assets/img/pages/vr-games/is/Life Saving Rules Icons-03.jpg'
                alt='Life-Saving Rules Icon 3'
                title='Life-Saving Rules Icon 3'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
                className="is-image"
              />
              <StaticImage
                src='../../assets/img/pages/vr-games/is/Life Saving Rules Icons-04.jpg'
                alt='Life-Saving Rules Icon 4'
                title='Life-Saving Rules Icon 4'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
                className="is-image"
              />
              <StaticImage
                src='../../assets/img/pages/vr-games/is/Life Saving Rules Icons-05.jpg'
                alt='Life-Saving Rules Icon 5'
                title='Life-Saving Rules Icon 5'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
                className="is-image"
              />
            </div> */}
            <h2 className="main-subtitle">Training</h2>
            <p className="no-mar-top">
            Workers should be trained in these IOGP Life-Saving Rules. Preferably, the training should give a realistic impression of the most hazardous work activities and how the Life-Saving Rules can protect the workers. The training should result in the workers recognizing the fatal hazards in their work and can make interventions to change the work conditions.
            </p>
            <p>
            Currently, various training techniques exist to expose workers to simulated high-risk situations and test their reactions. However, there is no equivalent technique to VR which is cost effective, risk-free, and able to test trainees’ reactions and interventions.
            </p>

            <h2 className="main-subtitle">NEW Virtual Reality Technology</h2>
            <p className="no-mar-top mar-bottom">
            The Virtual Reality Technology now breaks through into the consumer markets, as the devices become simple to use (plug-and-play, wireless) and affordable (less costly than a smartphone). Research has shown<Link to="#link-group3"><sup>[6]</sup></Link> that VR Safety Training enhances the training results significantly in comparison with classroom or e-learning.  It gives trainees realistic situations of their workplace without putting them at risk. In addition, the trainees can get constructive feedback on their actions and behaviour without being afraid of making mistakes. VR is in fact an advanced “simulator” where the workers can practice their skills.
            </p>

            <div onClick={()=>handeleActiveImg(vrTech)} aria-hidden style={{cursor:'zoom-in'}}>

              <StaticImage
                src='../../assets/img/pages/vr-games/NEW Virtual Reality Technology.jpg'
                alt='NEW Virtual Reality Technology'
                title='NEW Virtual Reality Technology'
                quality="100"
                objectFit
                style={{width:'100%'}}
                imgStyle={{width:'100%'}}
              />
            </div>


            <h2 className="main-subtitle">Available Training Courses</h2>
            <p className="no-mar-top">
            VIRSAT LLC makes use of behavioral psychology learning methodologies to train workers in the field with VR applications, so workers can practice in a safe environment. Workers can make mistakes and learn from these. VIRSAT makes use of different game levels to provide basic information to learners (<strong><i>“I know the rules”</i></strong>) towards skilled levels (<strong><i>“I know, I can do, I can explain the rules”</i></strong>). Workers tell us that VR makes learning (<strong><i>“insightful, fun, and meaningful”</i></strong>).
            </p>

            <div>
              <ul className="links-anchors" id="link-group3">
                <li id="link1">
                  <a href="https://injuryfacts.nsc.org/international/work-related-injuries-around-the-world/work-related-deaths-around-the-world/">[1] https://injuryfacts.nsc.org/international/work-related-injuries-around-the-world/work-related-deaths-around-the-world/</a> (reported fatalities by UN-ILC data based on historic reporting); Based on world fraction of inhabitants USA+EU 2020, total number of fatalities is estimated.
                </li>
                <li id="link2">
                  <a href="https://ec.europa.eu/eurostat/databrowser/bookmark/5a980fe1-9caf-47e2-9b03-8b988bbb5d94?lang=en">[2] Fatal Accidents at work by NACE Rev. 2 activity [HSW_N2_02]</a> reference period 2011-2018
                </li>
                <li id="link3"><a href="https://onepetro.org/SPEHSE/proceedings/12HSE/All-12HSE/SPE-157465-MS/158675">[3] A Big Oil Company's Approach to Significantly Reduce Fatal Incidents</a></li>
                <li id="link4">
                  <a href="https://onepetro.org/SPEHSE/proceedings/12HSE/All-12HSE/SPE-157465-MS/158675">[4] A Big Oil Company's Approach to Significantly Reduce Fatal Incidents</a> 
                </li>
                <li id="link5">
                  <a href="https://www.iogp.org/life-savingrules/">[5] IOGP Life-Saving Rules, report 459</a>
                </li>
                <li id="link6">
                  <a href="https://www.pwc.com/us/en/tech-effect/emerging-tech/virtual-reality-study.html">[6] https://www.pwc.com/us/en/tech-effect/emerging-tech/virtual-reality-study.html</a>
                </li>
              </ul>
            </div>
            <div className="footer-note primary-bg">
              <div>
                <p>
                If you want to learn more, see the brochure - <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf">Life-Saving Rules Aware-Knowledge</a>, or write to <a href="mailto:info@virsat.com">info@virsat.com</a>. 
                </p>
              </div>
              <div>
                <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf"><img src={lsrImg} alt='VIRSAT Life Saving Rules Aware'/></a>
              </div>
              {/* <p className="light center no-mar">An Industry standard training for the Life-Saving Rules is available. If you want to learn more, see the brochure - <a className="secondary-color" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf">Life-Saving Rules Aware</a>, or write to <a href="mailto:info@virsat">info@virsat.com</a>. To subscribe to the newsletter, visit <a href="!#">(link-is-on-the-process)</a>.</p> */}
            </div>
            <div className="subscribe-container">
              {/* <p className="center">To subscribe to the newsletter, visit <Link to="/lp/newsletter/">https://virsat.com/lp/newsletter/</Link>.</p> */}
              <a className="button-link secondary" href="https://secure.virsat.com/pdf/VR%20Games_%20A%20New%20Way%20to%20Make%20Work%20Significantly%20Safer.pdf" target="_blank" rel="noreferrer">Download Article (PDF)</a>
            </div>
          </div>
          <ul className="footer-social">
            <li>
              <a className="linkedin" href="https://www.linkedin.com/company/virtualrealitysafetytraining">
                <img src={ln} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a className="facebook" href="https://www.facebook.com/VirtualRealitySafetyTraining">
                <img src={fb} alt="Facebook" />
              </a>
            </li>
            <li>
              <a className="youtube" href="https://www.youtube.com/channel/UCwA2cT9UaygadY4esRPSkrw/featured">
                <img src={yt} alt="Yuotube" />
              </a>
            </li>
            <li>
              <a className="twitter" href="https://twitter.com/virsat_llc">
                <img src={tw} alt="Twitter" />
              </a>
            </li>
          </ul>

          <p className="copyright">Copyright © 2021 VIRSAT, All rights reserved.</p>
          <Link className="footer-logo" to="/">
            <img src={logo} alt="VIRSAT Logo"/>
          </Link>
        </div>
      </div>
    </Fragment>
  )
}

export default VrGames
